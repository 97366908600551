$(document).ready(function () {
    
    // START MOBILE MENU CLICK
    var $topNavigation = $(".navigation-wrapper.top");
    $('#nav-trigger').click(function(event){
        $topNavigation.slideToggle(500);
        $(".nav-trigger").toggleClass('active');
        $('.main-menu-top-wrapper').toggleClass('active-menu');
        event.stopPropagation();
    });

    //CLICK OUTSIDE CLOSE MOBILE NAVIGATION
    var alterClass = function() {
        var ww = document.body.clientWidth;
        if (ww < 751) {
            $('body').not('#nav-trigger').click(function(){
                $topNavigation.slideUp(500);
                $(".nav-trigger").removeClass('active');
                $('.main-menu-top-wrapper').removeClass('active-menu');
            });
        }
      };
      $(window).resize(function(){
        alterClass();
      });
      
      //Fire it when the page first loads:
      alterClass();
    // END MOBILE MENU CLICK

    //START REACTIONS button hover animation
    $('.reaction-btn').on('mouseenter',function(){
        $(this).prev().prev().addClass('animate-reaction');
    });

    $('.reaction-btn').on('mouseleave',function(){
        $(this).prev().prev().removeClass('animate-reaction');
    });
    //END REACTIONS button hover animation

    //START REACTION CLICK ADDED CLASS
    $('.reaction-btn').on('click',function(e){
        $(this).toggleClass('reacted');

        if($(this).hasClass('disabled')){
            $(this).removeClass('reacted');
        }else if($('.reaction-btn').not(this).hasClass('reacted')){
            $('.reaction-btn.reacted').removeClass('disabled');
        }
        else if(!$(this).hasClass('reacted')){
            $('.reaction-btn').removeClass('disabled');
        }
        else{
            $('.reaction-btn').not(this).addClass('disabled');
        }

        //INCREMENT QUERY
        if(!$(this).hasClass('disabled')){
            var counter = parseInt($(".hiddenVal").val());
            if($(this).hasClass('reacted')){
                counter++;
            } else {
                counter--;
            }

            $(this).next().val(counter);
            $(this).prev().text(counter);
        }
    });
    //END REACTION CLICK ADDED CLASS

    //MAIN Nav Active
    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
    $('.main-menu-top-wrapper a').each(function() {
        if (this.href === path) {
            $(this).addClass("active"); 
        }
    });

});